.body-div {
  background-image: url(./assets/img/ba_1.jpg);
  background-size: contain;
}
.navbar {
  box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
}
.navbar .navbar-collapse .navbar-nav .nav-link {
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  font-size: medium;
  display: inline-block;
  padding: 15px 10px;
  position: relative;
  font-weight: 600;
}
.navbar .navbar-collapse .navbar-nav .nav-link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 3px;
  left: 50%;
  position: absolute;
  background: #0d324d;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navbar .navbar-collapse .navbar-nav .nav-link:target {
  color: rgb(206, 7, 7) !important;
}

.navbar .navbar-collapse .navbar-nav .nav-link:hover:after {
  width: 100%;
  left: 0;
}
.navbar-light {
  background-color: #fff !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1);
}
.hero {
  min-height: 100vh;
  background-size: cover;
  background-image: url('./assets/img/2faad-slider-6.jpg');
  background-attachment: fixed;
}
/* For mobile devices */
@media only screen and (max-width: 767px) {
  .hero {
    /* The file size of this background image is 93% smaller
     * to improve page load speed on mobile internet connections */
    background-size: cover;
    background-image: url('./assets/img/2faad-slider-6.jpg');
    background-attachment: fixed;
    background-position-x: center;
  }
}
.btn-primary {
  font-size: 14px;
  padding: 10px 15px 10px 15px;
  background-color: #0981a3;
  border-radius: 50px;
  border: 2px solid transparent;
}

.btn-primary:hover {
  background-color: transparent;
  border: 2px solid #0981a3;
  color: black;
}

.btn-primary-form {
  font-size: 14px;
  width: 200px;
  padding: 10px 15px 10px 15px;
  background-color: #0981a3;
  border-radius: 50px;
  color: #fff;
  border: 2px solid transparent;
}
.btn-primary-form:hover {
  background-color: transparent;
  border: 2px solid #0981a3;
  color: black;
}
.nile-about-section {
  padding: 30px 0px 30px 0px;
  background-size: cover;
  text-align: justify;
}
.nile-about-section h5 {
  color: #0981a3;
}
.nile-fre-section {
  background-size: cover;
  text-align: justify;
}
.nile-fre-section h4 {
  color: #01a1ad;
  font-size: clamp(1.4rem, 2.5vw, 2rem);
  margin-bottom: 30px;
}

.section-title-right .icon {
  float: left;
  margin-right: 20px;
  margin-top: -10px;
}
.section-title .des {
  opacity: 0.8;
}

a.nile-bottom {
  background-color: #e53935;
  color: #fff;
  border-radius: 35px;
  display: inline-block;
}
a.nile-bottom:hover {
  background-color: #999;
  color: #fff;
}
a.nile-bottom.sm {
  padding: 6px 30px 6px 30px;
}
a.nile-bottom.md {
  padding: 6px 40px 6px 40px;
  -webkit-box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
}
a.nile-bottom.md .fa {
  margin-left: 10px;
}
a.nile-bottom.sm-block {
  display: block;
  padding: 6px 40px 6px 40px;
  -webkit-box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
  font-weight: 500;
}
a.nile-bottom.sm-block .fa {
  margin-left: 10px;
}

.background-main-color-1 {
  background-color: #fff;
}

.section-title {
  text-align: center;
}
.section-title .icon {
  font-size: 25px;
}
.section-title .h2 {
  font-size: 28px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  display: inline-block;
  position: relative;
}
.section-title .h2:before {
  border-bottom: 2px solid #d1d1d1;
  content: ' ';
  width: 100px;
  position: absolute;
  left: -120px;
  right: 0;
  bottom: 12px;
}
.section-title .h2:after {
  border-bottom: 2px solid #d1d1d1;
  content: ' ';
  width: 100px;
  position: absolute;
  right: -120px;
  bottom: 12px;
}
.section-title .des {
  opacity: 0.8;
}

.border-radius-500 {
  border-radius: 500px;
}
.service-icon-box {
  text-align: center;
  margin-bottom: 35px;
}
.service-icon-box a.title.h2 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 15px auto;
}
.service-icon-box .des {
  font-size: 13px;
  color: #777;
}
.sm-mb-40px {
  margin-bottom: 40px !important;
}
.m-card {
  color: #fff;
  background-color: #0981a3;
}
.m-card h3 {
  color: #fff;
  filter: brightness(0) invert(1);
}
.m-card img {
  filter: brightness(0) invert(1);
}
.m-card-1 {
  color: #fff;
  background-color: #0ca2ae;
}
.m-card-1 h3 {
  color: #fff;
  filter: brightness(0) invert(1);
}
.m-card-1 img {
  filter: brightness(0) invert(1);
}
/*  */
.whyuscard-default {
  background-color: #0981a3;
  height: 262px;
  display: grid;
  place-items: center;
}
.whyuscard-green {
  background-color: #09a34e;
  height: 262px;
  display: grid;
  place-items: center;
}
.whyuscard-green {
  background-color: #09a34e;
  height: 262px;
  display: grid;
  place-items: center;
}
.whyuscard-second {
  background-color: #0ca2ae;
  height: 262px;
  display: grid;
  place-items: center;
}

.whyuscard-default h5 {
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.whyuscard-second h5 {
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.contact-elements {
  display: inline-flex;
  align-items: baseline;
}

.contact-elements i {
  font-size: 32px;
}

.contact-elements p {
  font-size: 18px;
  padding-left: 20px;
}

footer {
  background-color: rgb(15, 7, 7);
  color: #fff;
  text-align: center;
}
footer p {
  padding: 5px;
  margin-bottom: 0px;
  font-size: smaller;
  color: #d1d1d1;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #0d324d;
  opacity: 0.85;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.navbar-toggler:focus {
  outline: none;
}

.service-section {
  padding: 80px 20px 80px 20px;
  background-size: cover;
  text-align: justify;
}

.service-section h4 {
  color: #08a4af;
  font-size: 32px;
}
.service-section em {
  color: #888888;
}

.service-section img {
  max-width: 100%;
}
.b-shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
